import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, proposal, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        if (i18n.locale === 'bn') {
          pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        let pdfContent = []
        pdfContent = [
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]

        pdfContent.push({ text: '', style: 'fertilizer' })
        const coInvestigatorsNames = proposal.co_investigators.map(item => {
          return vm.$i18n.locale === 'en' ? item.investigator_name_en : item.investigator_name_bn
        }).join(', ')
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '*'], // Setting column widths in points
            body: [
              [{ text: vm.$t('research_manage.thematic_area_name'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + vm.getThematicArea(proposal.thematic_area_id), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('research_manage.project_name'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (proposal.master_project_setup_details ? (vm.$i18n.locale === 'en' ? proposal.master_project_setup_details.project_name_en : proposal.master_project_setup_details.project_name_bn) : ''), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('research_manage.project') + vm.$t('research_manage.objectives'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (proposal.master_project_setup_details ? (vm.$i18n.locale === 'en' ? proposal.master_project_setup_details.objective_en : proposal.master_project_setup_details.objective_bn) : ''), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.principal_investigator'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.officerList.length ? vm.getOfficer(proposal.pi_id) : ''), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.experiment'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.experiment : proposal.experiment_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('research_manage.objectives'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.objective : proposal.objective_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('globalTrans.status'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.status_en : proposal.status_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.rationale'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.rationale : proposal.rationale_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.materials_methods'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.exp_materials : proposal.exp_materials_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.exp_materials'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.exp_materials : proposal.exp_materials_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.design'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.design : proposal.design_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.treatment'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.treatment : proposal.treatment_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.location'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.location : proposal.location_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.plot_size'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.plot_size : proposal.plot_size_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.planting_spacing'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.planting_spacing : proposal.planting_spacing_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.fertilizer_dose_method_application'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.fertilizer_dose_method : proposal.fertilizer_dose_method_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.irrigated_rainfed'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.irrigated_rainfed : proposal.irrigated_rainfed_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.data_to_be_recorded'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.data_recorded : proposal.data_recorded_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.principal_co_investigator_name'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + coInvestigatorsNames, alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.season'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + JSON.parse(proposal.season).map(item => vm.getSeason(item)), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('globalTrans.start_date'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.getCurrentMonth(proposal.month_from) + ',' + proposal.year_from), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('globalTrans.end_date'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.getCurrentMonth(proposal.month_to) + ',' + proposal.year_to), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.expected_output_benifit'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.expected_output : proposal.expected_output_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.estimated_cost'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + proposal.estimated_cost, alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.source_fund'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.source_fund : proposal.source_fund_bn), alignment: 'left', style: 'td' }],
              [{ text: vm.$t('external_research.priority'), style: 'th', alignment: 'left', bold: true }, { text: ': ' + (vm.currentLocale === 'en' ? proposal.priority : proposal.priority_bn), alignment: 'left', style: 'td' }]
            ]
          },
          layout: {
            defaultBorder: 0 // Setting default border to 0 for all cells
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            bold: true
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, -20]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
      // pdfMake.createPdf(docDefinition, null, null, null).download('research-project-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}

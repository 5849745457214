
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_sidebar.proposal_submission') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('external_research.research_type')"
                  label-for="pub_type"
                  >
                  <b-form-select
                  plain
                  v-model="search.research_type"
                  :options="researchTypeList"
                  id="pub_type"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_title')"
                  label-for="pub_title"
                  >
                  <b-form-input
                  plain
                  v-model="search.proposal_title"
                  id="pub_title"
                  >
                  </b-form-input>
              </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_sidebar.proposal_submission') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(proposal_status)="data">
                        <span class="badge badge-danger" v-if="data.item.flag == 0">{{ $t('globalTrans.draft') }}
                          <span v-if="data.item.proposal_status === 1">{{ $t('globalTrans.and') + ' ' + $t('globalTrans.returned') }}</span>
                        </span>
                        <span v-else>
                            <span class="badge badge-warning" v-if="data.item.proposal_status == 0">{{ $t('globalTrans.processing') }}</span>
                            <span class="badge badge-danger" v-else-if="data.item.proposal_status == 1">{{ $t('globalTrans.returned') }}</span>
                            <span class="badge badge-danger" v-else-if="data.item.proposal_status == 3">{{ $t('globalTrans.rejected') }}</span>
                            <span class="badge badge-warning" v-else-if="data.item.proposal_status == 4">{{ $t('globalTrans.re_submitted') }}</span>
                            <span class="badge badge-success" v-else>{{ $t('globalTrans.approved') }}</span>
                        </span>
                    </template>
                    <template v-slot:cell(project_id)="data">
                      <slot v-if="data.item.org_id === 6">
                        {{ $i18n.locale === 'en' ? data.item.project_title : data.item.project_title }}
                      </slot>
                      <slot v-else>
                        <slot v-if="data.item.project_id > 0">
                          {{ data.item.master_project_setup_details  ? ($i18n.locale === 'en' ? data.item.master_project_setup_details.project_name_en : data.item.master_project_setup_details.project_name_bn) : ($i18n.locale === 'en' ? data.item.project_title : data.item.project_title_bn) }}
                        </slot>
                      </slot>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button  v-if=" data.item.project_id === null" title="View Details" v-b-modal.modal-5 variant="iq-bg-success border-info mr-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                        <b-button  v-if=" data.item.project_id > 0" title="View Details" v-b-modal.modal-6 variant="iq-bg-success border-info mr-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                        <b-button variant="iq-bg-success border-info mr-1" size="sm" v-if="data.item.research_type !== 3"><router-link v-if="data.item.flag === 0 || data.item.proposal_status == 1" title="Proposal Entry" :to="'proposal-submission-form?id='+data.item.id"><i class="ri-ball-pen-fill m-0"></i></router-link></b-button>
                        <b-button variant="iq-bg-success border-info mr-1" v-b-modal.modal-4 size="sm" v-if="data.item.research_type === 3 && data.item.flag === 0" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal no-close-on-backdrop id="modal-4" size="xl" :title="viewTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <BiriForm v-if="item.org_id === 6" :id="editItemId" :item="item" :key="editItemId"/>
      <Form v-else :id="editItemId" :item="item" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-5" size="xl" :title="viewTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-6" size="xl" :title="viewTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <BiriDetailsx v-if="item.org_id === 6" :id="editItemId" :key="editItemId" :isAdmin = "false"/>
      <DetailsX v-else :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-9" size="xl" :title="this.$t('external_sidebar.research_proposal_submission_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailsM :id="application" :key="application" />
    </b-modal>
  </b-container>
</template>
<script>
import Details from './Details.vue'
import Form from '../proposal-circular-list/Form.vue'
import DetailsX from '../proposal-circular-list/Detailsx.vue'
import BiriDetailsx from '../proposal-circular-list/BiriDetailsx.vue'
import DetailsM from './Detailsm.vue'
import BiriForm from '../proposal-circular-list/BiriForm'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalResearchSubmissionListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Form, DetailsX, DetailsM, BiriForm, BiriDetailsx
  },
  data () {
    return {
        item: '',
        editItemId: 0,
        search: {
            research_type: 0,
            proposal_title: ''
        }
    }
  },
  created () {
  },
  computed: {
    researchTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.researchTypeList.map(item => {
            return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
        })
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    viewTitle () {
       return this.$t('external_research.proposal_submission') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('external_research.proposal_id'), class: 'text-left' },
          { label: this.$t('external_research.biri_title'), class: 'text-left' },
          { label: this.$t('external_research.research_type'), class: 'text-left' },
          { label: this.$t('external_research.project_status'), class: 'text-center' },
        //   { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_id' },
          { key: 'research_type_name_bn' },
          { key: 'proposal_status' },
        //   { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_id' },
          { key: 'research_type_name' },
          { key: 'proposal_status' },
        //   { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'search.pub_type_id': function (newVal) {
    //   this.getPublicationTitleList()
    // },
    // currentLocale: function (newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //         if (this.search.pub_type_id) {
    //             this.getPublicationTitleList()
    //         }
    //     }
    // }
  },
  mounted () {
    core.index()
    this.loadData()
    flatpickr('#to_date', {})
    flatpickr('#from_date', {})
  },
  methods: {
    // remove (item) {
    //   this.changeStatus(agriResearchServiceBaseUrl, researchProposalToggleStatus, item, 'agri_research', 'publicationTypeList')
    // },
    async searchData () {
      this.loadData()
    },
    edit (item) {
      this.item = Object.assign({}, item, { id: item.circular_main_id, org_id: item.org_id })
      this.editItemId = item.id
    },
    view (item) {
      this.editItemId = item.id
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, proposalResearchSubmissionListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const tmpResearchType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.researchTypeList.find(newItem => newItem.value === item.research_type)
        const newData = {
            research_type_name: tmpResearchType !== undefined ? tmpResearchType.text_en : '',
            research_type_name_bn: tmpResearchType !== undefined ? tmpResearchType.text_bn : ''
        }
        const circular = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.circularList.find(newItem => newItem.value === item.circular_main_id)
        const cir = {
          circular_name: circular !== undefined ? circular.circular_name : '',
          memo_no: circular !== undefined ? circular.memo_no : ''
        }
        // const circular = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.circularList.find(newItem => newItem.value === parseInt(data.circular_main_id))
        return Object.assign({}, item, newData, cir)
      })
      return listData
    }
  }
}
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row v-if="!loadingData">
                    <b-col class="text-center">
                        <p>{{ (currentLocale === 'en') ? 'Loading! Please wait...' : 'লোড হচ্ছে! অনুগ্রহপূর্বক অপেক্ষা করুন...' }}</p>
                        <h1><i class="fas fa-spinner fa-pulse"></i></h1>
                    </b-col>
                </b-row>
                <b-row v-if="loadingData">
                    <b-col lg="12" sm="12">
                        <b-row>
                            <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                                <b-button class="text-right" @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xl="12" sm="12">
                                <b-row>
                                    <b-col md=12>
                                        <b-table-simple striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th colspan="6"><h5 class="text-white text-center contentTitle">{{ $t('certify_form.gen_info') }}</h5></b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('external_research.research_type') }}</b-th>
                                                    <b-td>{{ (currentLocale === 'en') ? proposal.researchTypeName : proposal.researchTypeName_bn }}</b-td>
                                                    <b-th width="20%">{{ $t('research_manage.thematic_area') }}</b-th>
                                                    <b-td>{{ (currentLocale === 'en') ? proposal.thematic_area_name : proposal.thematic_area_name_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('globalTrans.organization') }}</b-th>
                                                    <b-td>{{ getOrgName(proposal.org_id) }}</b-td>
                                                    <b-th>{{ $t('org_pro.office_type') }}</b-th>
                                                    <b-td>{{ getOfficeType(proposal.office_type_id) }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('org_pro.office') }}</b-th>
                                                    <b-td colspan="3">{{ getOfficeName(proposal.office_id) }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.season') }}</b-th>
                                                    <b-td  colspan="3">{{ getSeason(proposal.season) }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type !== 3">
                                                    <b-th>{{ $t('research_manage.project_title') }}</b-th>
                                                    <b-td colspan="3">{{ (currentLocale === 'en') ? proposal.project_title : proposal.project_title_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-else>
                                                    <b-th>{{ $t('external_research.experiment') }}</b-th>
                                                    <b-td colspan="3">{{ (currentLocale === 'en') ? proposal.experiment : proposal.experiment_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('research_manage.objectives') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.objective : proposal.objective_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('globalTrans.status') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.status_en : proposal.status_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.rationale') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.rationale : proposal.rationale_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th colspan="4">{{ $t('external_research.materials_methods') }}</b-th>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.exp_materials') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.exp_materials : proposal.exp_materials_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.design') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.design : proposal.design_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.treatment') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.treatment : proposal.treatment_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.location') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.location : proposal.location_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.plot_size') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.plot_size : proposal.plot_size_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.planting_spacing') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.planting_spacing : proposal.planting_spacing_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.fertilizer_dose_method_application') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.fertilizer_dose_method : proposal.fertilizer_dose_method_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.irrigated_rainfed') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.irrigated_rainfed : proposal.irrigated_rainfed_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.data_to_be_recorded') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.data_recorded : proposal.data_recorded_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.expected_output_benifit') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.expected_output : proposal.expected_output_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.source_fund') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.source_fund : proposal.source_fund_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.priority') }}</b-th>
                                                    <b-td  colspan="3">{{ (currentLocale === 'en') ? proposal.priority : proposal.priority_bn }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th colspan="4">{{ $t('external_research.duration') }}</b-th>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.starting_time') }}</b-th>
                                                    <!-- <b-td  colspan="3">{{ proposal.from_date }}</b-td> -->
                                                    <b-td  colspan="3">{{  getMonth(proposal.month_from) }} {{ proposal.year_from }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.ending_time') }}</b-th>
                                                    <!-- <b-td  colspan="3">{{  proposal.to_date }}</b-td> -->
                                                    <b-td  colspan="3">{{  getMonth(proposal.month_to) }} {{  proposal.year_to }}</b-td>
                                                </b-tr>
                                                <b-tr v-if="proposal.research_type === 3">
                                                    <b-th>{{ $t('external_research.estimated_cost') }}</b-th>
                                                    <b-td  colspan="3">{{ proposal.estimated_cost }}</b-td>
                                                </b-tr>
                                                <b-tr  v-if="proposal.research_type !== 3">
                                                    <b-th>{{ $t('external_research.project_duation_from_date') }}</b-th>
                                                    <b-td>{{ proposal.from_date | dateFormat }}</b-td>
                                                    <b-th>{{ $t('external_research.project_duation_to_date') }}</b-th>
                                                    <b-td>{{ proposal.to_date | dateFormat }}</b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.research_type === 2" striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th colspan="6"><h5 class="text-white text-center contentTitle">{{ $t('external_research.lead_org_and_coordinator') + ' ' + $t('sidebar.information') }}</h5></b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('research_manage.organization_type') }}</b-th>
                                                    <b-td>{{ getOrgTypeName(proposal.org_type) }}</b-td>
                                                    <b-th width="20%">{{ proposal.org_type == 3 ? $t('research_manage.university_name') : $t('external_research.lead_org') }}</b-th>
                                                    <b-td>{{ getComponentOrgName(proposal.org_type, proposal.lead_org_id) }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('globalTrans.name') }}</b-th>
                                                    <b-td>{{ (currentLocale === 'en') ? proposal.coordinator_info.coord_name : proposal.coordinator_info.coord_name_bn }}</b-td>
                                                    <b-th>{{ $t('globalTrans.designation') }}</b-th>
                                                    <b-td>{{ (currentLocale === 'en') ? proposal.coordinator_info.coord_designation : proposal.coordinator_info.coord_designation_bn }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('globalUserData.mobile_no') }}</b-th>
                                                    <b-td>{{ proposal.coordinator_info.coord_mobile_no | mobileNumber}}</b-td>
                                                    <b-th>{{ $t('globalTrans.email') }}</b-th>
                                                    <b-td>{{ proposal.coordinator_info.coord_email }}</b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.research_type === 2" striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-th colspan="5">
                                                        <h5 class="text-white text-center contentTitle">{{ $t('external_research.component_org') + ' ' + $t('sidebar.information') }}
                                                        </h5>
                                                    </b-th>
                                                </b-tr>
                                                <tr>
                                                    <th class="text-center mytd" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th scope="col">{{ $t('research_manage.organization_type') }}</th>
                                                    <th scope="col">{{ $t('research_manage.org_or_univ_name') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody v-if="proposal.coordinator_orgs.length > 0">
                                                <b-tr v-for="(item, index) in proposal.coordinator_orgs" :key="index">
                                                    <b-td class="text-center mytd">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ getOrgTypeName(item.org_type) }}</b-td>
                                                    <b-td>{{ getComponentOrgName(item.org_type, item.component_org_id) }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.co_investigator" striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-th colspan="7">
                                                        <h5 class="text-white text-center contentTitle">{{ $t('external_research.principal_investigator') + ' ' + $t('sidebar.information') }}
                                                        </h5>
                                                    </b-th>
                                                </b-tr>
                                                <tr>
                                                    <th class="text-center mytd" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th scope="col">{{ $t('research_manage.org_or_univ_name') }}</th>
                                                    <th scope="col">{{ $t('external_research.principal_investigator_name') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.designation') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.address') }}</th>
                                                    <th scope="col">{{ $t('globalUserData.mobile_no') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.email') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody v-if="proposal.co_investigator.investigators.length > 0">
                                                <b-tr v-for="(item, index) in proposal.co_investigator.investigators" :key="index">
                                                    <b-td class="text-center mytd">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ getComponentOrgName(item.org_type, item.component_org_id) }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.investigator_name_bn : item.investigator_name }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.designation_bn : item.designation }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.address_bn : item.address }}</b-td>
                                                    <b-td>{{ item.mobile_no | mobileNumber }}</b-td>
                                                    <b-td>{{ item.email }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.co_investigator" striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th colspan="6"><h5 class="text-white text-center contentTitle">{{ $t('external_research.principal_co_investigator') + ' ' + $t('sidebar.information') }}</h5></b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th width="20%">{{ proposal.co_investigator.org_type == 3 ? $t('research_manage.university_name') : $t('external_research.component_org') }}</b-th>
                                                    <b-td width="30%">{{ getComponentOrgName(proposal.co_investigator.org_type, proposal.co_investigator.component_org_id) }}</b-td>
                                                    <b-th width="20%">{{ $t('external_research.principal_investigator_name') }}</b-th>
                                                    <b-td width="30%">{{ currentLocale == 'bn' ? proposal.co_investigator.co_investigator_name_bn : proposal.co_investigator.co_investigator_name }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('globalTrans.designation') }}</b-th>
                                                    <b-td>{{ currentLocale == 'bn' ? proposal.co_investigator.designation_bn : proposal.co_investigator.designation }}</b-td>
                                                    <b-th>{{ $t('globalTrans.address') }}</b-th>
                                                    <b-td>{{ currentLocale == 'bn' ? proposal.co_investigator.address_bn : proposal.co_investigator.address }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('globalUserData.mobile_no') }}</b-th>
                                                    <b-td>{{ proposal.co_investigator.mobile_no | mobileNumber }}</b-td>
                                                    <b-th>{{ $t('globalTrans.email') }}</b-th>
                                                    <b-td>{{ proposal.co_investigator.email }}</b-td>
                                                </b-tr>
                                            </tbody>
                                            <tbody v-if="proposal.description">
                                                <b-tr>
                                                    <b-th colspan="6"><h5 class="text-white text-center contentTitle">{{ $t('external_research.project_description') }}</h5></b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.background') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.description.background_bn : proposal.description.background }}</b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-th>{{ $t('external_research.goal') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.description.goal_bn : proposal.description.goal }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.general_objectives') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.description.general_objective_bn : proposal.description.general_objective }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.targeted_beneficiaries') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.description.targeted_beneficiaries_bn : proposal.description.targeted_beneficiaries }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.literature_review') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.description.literature_review_bn : proposal.description.literature_review }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.bench_mark_info') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.description.targeted_beneficiaries_bn : proposal.description.targeted_beneficiaries }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('external_research.reference') }}</b-th>
                                                    <b-td colspan="3"><span v-html="proposal.description.reference"></span></b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-th colspan="6"><h5 class="text-white text-center contentTitle">{{ $t('external_research.specific_objectives') }}</h5></b-th>
                                                </b-tr>
                                                <b-tr v-for="(item, index) in proposal.description.details" :key="index">
                                                    <b-td width="20%" class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td colspan="3">{{ currentLocale == 'en' ? item.specific_objective : item.specific_objective_bn }}</b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.locations.length > 0" striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-th colspan="4">
                                                        <h5 class="text-white text-center contentTitle">{{ $t('external_research.implementation_location') + ' ' + $t('sidebar.information') }}
                                                        </h5>
                                                    </b-th>
                                                </b-tr>
                                                <tr>
                                                    <th class="text-center" width="10%">{{ $t('globalTrans.sl_no') }}</th>
                                                    <!-- <th width="30%" scope="col">{{ $t('globalTrans.org_name') }}</th> -->
                                                    <th width="30%" scope="col">{{ $t('external_research.division_section') }}</th>
                                                    <th width="30%" scope="col">{{ $t('globalTrans.address') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.locations" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <!-- <b-td>{{ currentLocale == 'bn' ? item.org_name_bn : item.org_name }}</b-td> -->
                                                    <b-td>{{ currentLocale == 'bn' ? item.division_bn : item.division }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.address_bn : item.address }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.activities.length > 0" striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-th colspan="10">
                                                        <h5 class="text-white text-center contentTitle">{{ $t('external_research.activity_list') + ' ' + $t('sidebar.information') }}
                                                        </h5>
                                                    </b-th>
                                                </b-tr>
                                                <tr>
                                                    <th class="text-center" width="10%">{{ $t('external_research.activity_no') }}</th>
                                                    <th scope="col">{{ $t('external_research.specific_objectives') }}</th>
                                                    <th scope="col">{{ $t('external_research.activity_name') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.start_date') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.end_date') }}</th>
                                                    <th scope="col">{{ $t('external_research.monitoring_indigator') }}</th>
                                                    <th scope="col">{{ $t('external_research.expected_output') }}</th>
                                                    <th scope="col">{{ $t('external_research.baseline_year') }}</th>
                                                    <th scope="col">{{ $t('external_research.baseline_value') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.description') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.activities" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ item.specific_obj_id ? getObjectiveName(item.specific_obj_id) : '' }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.activity_name_bn : item.activity_name }}</b-td>
                                                    <b-td>{{ item.start_date | dateFormat }}</b-td>
                                                    <b-td>{{ item.end_date | dateFormat }}</b-td>
                                                    <b-td>{{ item.monitoring_indigator }}</b-td>
                                                    <b-td>{{ item.expected_output }}</b-td>
                                                    <b-td>{{ $n(item.baseline_year, { useGrouping: false }) }}</b-td>
                                                    <b-td>{{ item.baseline_value }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.description_bn : item.description }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.methodology" striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th colspan="2"><h5 class="text-white text-center contentTitle">
                                                        {{ $t('external_research.research_methodology') + ' ' + $t('globalTrans.and') + ' ' + $t('external_research.expected_output_outcome') }}
                                                    </h5>
                                                    </b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('external_research.approach_and_methodology') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.methodology.approach_and_methodology_bn : proposal.methodology.approach_and_methodology }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('globalTrans.attachment') }}</b-th>
                                                    <b-td colspan="3">
                                                        <a v-if="proposal.methodology.attachment" target="_blank" :href="baseUrl + 'storage/uploads/research-management/original/' + proposal.methodology.attachment" title="Attachment" class="mr-3">{{ proposal.methodology.attachment }}<i class="ri-download-cloud-fill"></i></a>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.output') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.methodology.output_bn : proposal.methodology.output }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.outcome') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.methodology.outcome_bn : proposal.methodology.outcome }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th>{{ $t('external_research.proposal_submission_attachment') }}</b-th>
                                                    <b-td colspan="3">
                                                        <!-- <a target="_blank" :href="baseUrl + 'storage/uploads/research-management/original/' + methodology.proposal_submission_attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a> -->
                                                        <a v-if="proposal.methodology.proposal_submission_attachment" target="_blank" :href="baseUrl + 'storage/uploads/research-management/original/' + proposal.methodology.proposal_submission_attachment" title="Attachment" class="mr-3">{{ proposal.methodology.proposal_submission_attachment }}<i class="ri-download-cloud-fill"></i></a>
                                                    </b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.budget" striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-th colspan="7">
                                                        <h5 class="text-white text-center contentTitle">{{ $t('external_research.budget_info') }}
                                                        </h5>
                                                    </b-th>
                                                </b-tr>
                                                <tr>
                                                    <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th scope="col">{{ $t('research_manage.budget_type') }}</th>
                                                    <th scope="col">{{ $t('external_research.budget_head') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.code') }}</th>
                                                    <th scope="col">{{ $t('external_research.expense_year') }}</th>
                                                    <th scope="col">{{ $t('external_research.budget_percentage') }}</th>
                                                    <th scope="col">{{ $t('external_research.allocation_amount') + '' + $t('globalTrans.taka') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.budget.details" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ getBudgetTypeName(item.budget_type_id) }}</b-td>
                                                    <b-td>{{ getBudgetName(item) }}</b-td>
                                                    <b-td>{{ $n(item.code, { useGrouping: false }) }}</b-td>
                                                    <b-td>{{ getExpenseYearName(item.expense_year) }}</b-td>
                                                    <b-td>{{ $n(item.budget_percentage) }}</b-td>
                                                    <b-td>{{ Number.isInteger(parseFloat(item.allocation_amount)) ? ($n(item.allocation_amount) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.allocation_amount) }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td class="align-middle" colspan="2">
                                                        <p class="text-left m-0 p-0">{{$t('external_research.target_budget') + ' ' + $t('globalTrans.taka') + ' = ' + $n(proposal.budget.target_budget) }}</p>
                                                    </b-td>
                                                    <b-td colspan="4">
                                                        <p class="text-right m-0 p-0">{{ $t('external_research.total_budget') + ' (%)' }}</p>
                                                        <p class="text-right">{{ $t('external_research.total_budget') + ' ' + $t('globalTrans.taka') }}</p>
                                                    </b-td>
                                                    <b-td>
                                                    <p class="text-left m-0 p-0">{{ $n(proposal.budget.total_budget_perc) }}</p>
                                                    {{ Number.isInteger(parseFloat(proposal.budget.total_budget)) ? ($n(proposal.budget.total_budget) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(proposal.budget.total_budget) }}
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.proposal_status === 1 || proposal.proposal_status === 3 || proposal.proposal_status === 4" striped bordered small hover>
                                            <tbody>
                                                <b-tr>
                                                    <b-th colspan="2"><h5 class="text-white text-center contentTitle">
                                                        {{ $t('globalTrans.remarks') }}
                                                    </h5>
                                                    </b-th>
                                                </b-tr>
                                                <b-tr>
                                                    <b-th width="20%">{{ $t('globalTrans.remarks') }}</b-th>
                                                    <b-td colspan="3">{{ currentLocale == 'bn' ? proposal.note_bn : proposal.note }}</b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                        <b-table-simple v-if="proposal.research_type === 3" striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th class="text-center">{{ $t('external_research.principal_co_investigator_name') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.co_investigators" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.investigator_name_bn : item.investigator_name_en }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ExportPdf from './details_pdf'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchProposalDetailsApi } from '../../../api/routes'
export default {
    props: ['id'],
    data () {
        return {
            loadingData: false,
            proposal: {},
            baseUrl: agriResearchServiceBaseUrl,
            startingDate: '',
            endingDate: ''
        }
    },
    created () {
        if (this.id) {
            this.loadData()
        }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
        seasonList () {
            const seasonList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Kharif-1' : 'খরিফ-১', text_en: 'Kharif-1', text_bn: 'খরিফ-১' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Kharif-2' : 'খরিফ-২', text_en: 'Kharif-2', text_bn: 'খরিফ-২' }
            ]
            return seasonList
        }
    },
    methods: {
      getSeason (seasonIds) {
        if (seasonIds !== null) {
            const arrayData = JSON.parse(seasonIds)
          const arr = []
          if (arrayData.constructor === Array) {
            arrayData.forEach(item => {
            const seasonId = this.seasonList.find(obj => obj.value === item)
            const seasonName = this.$i18n.locale === 'en' ? ' ' + seasonId.text_en : ' ' + seasonId.text_bn
            arr.push(seasonName)
            })
          }
          var concatenatedString = arr.join(' - ')
          return concatenatedString.toString()
        }
      },
        getObjectiveName (Id) {
            if (this.proposal.description.details.length > 0) {
                const Obj = this.proposal.description.details.find(item => item.id === parseInt(Id))
                if (Obj) {
                    if (this.$i18n.locale === 'en') {
                        return Obj.specific_objective
                    } else {
                        return Obj.specific_objective_bn
                    }
                } else {
                    return ''
                }
            }
        },
        getBudgetTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.find(item => item.value === parseInt(Id))
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOrgTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.organizationType.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOrgName (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getMonth (Id) {
            const Obj = this.$store.state.commonObj.monthList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getYear (Id) {
            const Obj = this.$store.state.commonObj.yearList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOfficeType (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOfficeName (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getDivisionName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.divisionalResearchList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getSeasonName (Id) {
            const Obj = this.seasonList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getBudgetName (myItem) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === parseInt(myItem.budget_head_id))
            myItem.code = Obj.budget_head_code
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getExpenseYearName (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        loadData () {
            RestApi.getData(agriResearchServiceBaseUrl, researchProposalDetailsApi, { proposal_id: this.id }).then(response => {
                if (response.success) {
                    this.proposal = this.getRelationalData(response.data)
                    this.loadingData = true
                }
                // this.loadingData = false
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (item) {
            const tmpResearchType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.researchTypeList.find(newitem => newitem.value === item.research_type)
            const tmpThematicArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === parseInt(item.thematic_area_id))
            const newData = {
                researchTypeName: tmpResearchType !== undefined ? tmpResearchType.text_en : '',
                researchTypeName_bn: tmpResearchType !== undefined ? tmpResearchType.text_bn : '',
                thematic_area_name: tmpThematicArea !== undefined ? tmpThematicArea.text_en : '',
                thematic_area_name_bn: tmpThematicArea !== undefined ? tmpThematicArea.text_bn : ''
            }
            return Object.assign({}, item, newData)
        },
        getComponentOrgName (type, Id) {
            if (type === 1) {
                const newObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                const Obj = this.$store.state.ExternalUserIrrigation.commonObj.orgList.find(item => item.value === newObj.org_id)
                if (Obj) {
                    if (this.$i18n.locale === 'en') {
                        return Obj.text_en
                    } else {
                        return Obj.text_bn
                    }
                } else {
                        return ''
                }
            } else {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                if (Obj) {
                    if (this.$i18n.locale === 'en') {
                        return Obj.text_en
                    } else {
                        return Obj.text_bn
                    }
                } else {
                        return ''
                }
            }
        },
        pdfExport () {
            const reportTitle = this.$t('external_sidebar.publication_proposal_submission') + ' ' + this.$t('globalTrans.details')
            ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this.proposal, this)
        }
    }
}
</script>
<style lang="scss">
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .contentTitle {
      font-size: 15px; background-color: #005B5B; padding: 1px
  }
</style>

<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row id="form">
                    <b-col lg="12" sm="12">
                        <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{ $t('external_research.proposal_submission')}}</legend>
                            <b-col lg="12" sm="12">
                                <b-table-simple bordered class="main-table">
                                     <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('org_pro.organization') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <slot v-if="isAdmin">
                                                {{ getColumnName($store.state.commonObj.organizationProfileList, proposal.org_id) }}
                                            </slot>
                                            <slot v-else>
                                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.organizationProfileList, proposal.org_id) }}
                                            </slot>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('org_pro.office_type') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="isAdmin">
                                                {{ getColumnName($store.state.commonObj.officeTypeList, proposal.office_type_id) }}
                                            </slot>
                                            <slot v-else>
                                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.officeTypeList, proposal.office_type_id) }}
                                            </slot>
                                        </b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('org_pro.office') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="isAdmin">
                                                {{ getColumnName($store.state.commonObj.officeList, proposal.office_id) }}
                                            </slot>
                                            <slot v-else>
                                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.officeList, proposal.office_id) }}
                                            </slot>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('research_manage.circular_name') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="isAdmin">
                                                {{ getColumnNameCircular($store.state.AgriResearch.commonObj.circularList, proposal.circular_main_id) }}
                                            </slot>
                                            <slot v-else>
                                                {{ getColumnNameCircular($store.state.ExternalAgriResearch.agriResearchCommonObj.circularList, proposal.circular_main_id) }}
                                            </slot>
                                        </b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('research_manage.memo_no') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="isAdmin">
                                                {{ getColumnNameCircular($store.state.AgriResearch.commonObj.circularList, proposal.circular_main_id, 'memo') }}
                                            </slot>
                                            <slot v-else>
                                                {{ getColumnNameCircular($store.state.ExternalAgriResearch.agriResearchCommonObj.circularList, proposal.circular_main_id, 'memo') }}
                                            </slot>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('research_manage.thematic_area_name') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="isAdmin">
                                                {{ getColumnName($store.state.AgriResearch.commonObj.thematicAreaList, proposal.thematic_area_id) }}
                                            </slot>
                                            <slot v-else>
                                                {{ getColumnName($store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList, proposal.thematic_area_id) }}
                                            </slot>
                                        </b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('research_manage.project_name') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="proposal.master_project_setup_details">
                                                {{ $i18n.locale === 'en' ? proposal.master_project_setup_details.project_name_en : proposal.master_project_setup_details.project_name_bn }}
                                            </slot>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('research_manage.sub_project_name') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            <slot v-if="proposal.master_project_sub_setup_details">
                                                {{ $i18n.locale === 'en' ? proposal.master_project_sub_setup_details.sub_project_name_en : proposal.master_project_sub_setup_details.sub_project_name_bn }}
                                            </slot>
                                        </b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">
                                            {{ getColumnName(researchType, proposal.type) }}
                                        </b-th>
                                        <b-td style="width:30%%;" class="text-left">
                                            {{ $i18n.locale === 'en' ? proposal.project_title : proposal.project_title_bn }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('research_manage.objectives') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.objective_bn"></span>
                                            <span v-else v-html="proposal.objective"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.introduction') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.introduction_bn"></span>
                                            <span v-else v-html="proposal.introduction"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.relational') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.relational_bn"></span>
                                            <span v-else v-html="proposal.relational"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.materials_methods') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.materials_methods_bn"></span>
                                            <span v-else v-html="proposal.materials_methods"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.expected_output') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.expected_output_bn"></span>
                                            <span v-else v-html="proposal.expected_output"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.expected_outcome') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.expected_outcome_bn"></span>
                                            <span v-else v-html="proposal.expected_outcome"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.conclusion') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left" colspan="3">
                                            <span v-if="$i18n.locale=='bn'" v-html="proposal.conclusion_bn"></span>
                                            <span v-else v-html="proposal.conclusion"></span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.status_of_activity') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">{{ proposal.status_of_activity }}</b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.date_of_initiation') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">{{ proposal.date_of_initiation }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.date_of_completion') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">{{ proposal.date_of_completion }}</b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.sdg_linkage') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">{{ proposal.sdg_linkage }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.pl') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">{{ proposal.pl }}</b-td>
                                        <b-th style="width:20%;" scope="row" class="text-left">{{ $t('external_research.cls') }}</b-th>
                                        <b-td style="width:30%%;" class="text-left">{{ proposal.cls }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </fieldset>
                        <fieldset class="p-2 w-100" v-if="proposal.source_of_budget[0].amount">
                            <legend class="px-2 w-50 shadow-sm">{{$t('external_research.source_of_budget')}}</legend>
                            <b-col lg="12" sm="12">
                                <table class="table main-table" style="width:100%" border="1">
                                    <thead class="thead">
                                        <tr>
                                            <th class="text-left" style="width:30%">{{ $t('globalTrans.description') }}</th>
                                            <th class="text-left" style="width:30%">{{ $t('globalTrans.amount') }}</th>
                                        </tr>
                                    </thead>
                                    <tr v-for="(project, index1) in proposal.source_of_budget" :key="index1">
                                        <td class="text-left">{{project.description}}</td>
                                        <td class="text-left">{{project.amount}}</td>
                                    </tr>
                                </table>
                            </b-col>
                        </fieldset>
                        <slot v-if="proposal.propsal_forward.length > 0">
                            <fieldset class="p-2 w-100" v-if="isAdmin">
                                <legend class="px-2 w-50 shadow-sm">{{ $t('budget.forwardComments') }}</legend>
                                <b-col lg="12" sm="12">
                                    <table class="table main-table" style="width:100%" border="1">
                                        <thead class="thead">
                                            <tr>
                                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                                <b-th>{{ $t('budget.sendFrom') }}</b-th>
                                                <b-th>{{ $t('budget.sendTo') }}</b-th>
                                                <b-th>{{ $t('budget.comment') }}</b-th>
                                            </tr>
                                        </thead>
                                        <tr v-for="(forward, index) in proposal.propsal_forward" :key="index">
                                            <b-td>{{ $n(index+1) }}</b-td>
                                            <b-td>{{ getUserName(forward.sender_id) }}</b-td>
                                            <b-td>{{ getUserName(forward.receiver_id) }}</b-td>
                                            <b-td>{{ forward.comment }}</b-td>
                                        </tr>
                                    </table>
                                </b-col>
                            </fieldset>
                        </slot>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
    .main-table th.text-left {
        padding: 3px !important;
    }
    .main-table td.text-left {
        padding: 3px !important;
    }
</style>

<script>
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['id', 'isAdmin'],
    data () {
        return {
            loadingData: false,
            proposal: {},
            unitLoad: false,
            officerList: [],
            baseUrl: agriResearchServiceBaseUrl,
            users: []
        }
    },
    created () {
        if (this.id) {
            const tmp = this.loadData()
            this.proposal = tmp
            if (this.proposal.propsal_forward.length > 0) {
                this.getForwardedUsers(this.proposal.propsal_forward)
            }
        }
    },
    computed: {
        researchType () {
            const researchType = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Expertise' : 'দক্ষতা', text_en: 'Expertise', text_bn: 'দক্ষতা' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Activity' : 'কার্যকলাপ', text_en: 'Activity', text_bn: 'কার্যকলাপ' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Study' : 'অধ্যয়ন', text_en: 'Study', text_bn: 'অধ্যয়ন' }
            ]
            return researchType
        }
    },
    methods: {
         async getForwardedUsers (forwards) {
            this.loading = true
            var userIds = []
            forwards.map((item, key) => {
                userIds.push(item.sender_id)
                userIds.push(item.receiver_id)
            })

            const params = Object.assign({}, this.search, { user_ids: userIds })
            await RestApi.getData(authServiceBaseUrl, '/user/user-list', params).then(response => {
                if (response.success) {
                    this.users = response.data
                } else {
                    this.users = []
                }
                this.loading = false
            })
        },
        getUserName (userId) {
            const user = this.users.find(user => user.value === userId)
            return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
        },
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                if (this.$i18n.locale === 'bn') {
                return obj.text_bn
                }
                return obj.text_en
            } else {
                return ''
            }
        },
        getColumnNameCircular (list, groupId, memo = null) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                if (memo) {
                    return obj.memo_no
                }
                if (this.$i18n.locale === 'bn') {
                return obj.circular_name_bn
                }
                return obj.circular_name
            } else {
                return ''
            }
        },
        loadData () {
          const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
          const data = JSON.parse(JSON.stringify(tmpData))
          return Object.assign(data)
        }
    }
}
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row>
                    <b-col lg="12" sm="12">
                        <b-row>
                            <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                                <b-overlay :show="unitLoad"><b-button class="text-right" @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button></b-overlay>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xl="12" sm="12">
                                <b-row>
                                    <b-col md=12>
                                    <b-table-simple>
                                            <tbody>
                                                <b-tr><b-th style="width:5%">{{ $t('research_manage.thematic_area_name') }}</b-th><b-td>: {{ getThematicArea(proposal.thematic_area_id) }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('research_manage.project_name') }}</b-th><b-td>: <span v-if="proposal.master_project_setup_details">{{ $i18n.locale === 'en' ? proposal.master_project_setup_details.project_name_en : proposal.master_project_setup_details.project_name_bn}}</span></b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('research_manage.objectives') }}</b-th><b-td>: <span v-if="proposal.master_project_setup_details">{{ $i18n.locale === 'en' ? proposal.master_project_setup_details.objective_en : proposal.master_project_setup_details.objective_bn}}</span></b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.principal_investigator') }}</b-th><b-td>: <b-overlay :show="unitLoad"><span v-if="officerList.length">{{ getOfficer(proposal.pi_id) }}</span></b-overlay></b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.experiment') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.experiment : proposal.experiment_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.objective') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.objective : proposal.objective_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('globalTrans.status') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.status_en : proposal.status_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.rationale') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.rationale : proposal.rationale_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.materials_methods') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.exp_materials : proposal.exp_materials_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.exp_materials') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.exp_materials : proposal.exp_materials_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.design') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.design : proposal.design_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.treatment') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.treatment : proposal.treatment_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.location') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.location : proposal.location_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.plot_size') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.plot_size : proposal.plot_size_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.planting_spacing') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.planting_spacing : proposal.planting_spacing_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.fertilizer_dose_method_application') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.fertilizer_dose_method : proposal.fertilizer_dose_method_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.irrigated_rainfed') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.irrigated_rainfed : proposal.irrigated_rainfed_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.data_to_be_recorded') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.data_recorded : proposal.data_recorded_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.principal_co_investigator_name') }}</b-th><b-td>: <span v-if="proposal.co_investigators.length">
                                                                                                                        <span v-for="(item, index4) in proposal.co_investigators" :key="index4">
                                                                                                                            {{ $i18n.locale === 'en' ? item.investigator_name_en : item.investigator_name_bn }},
                                                                                                                        </span>
                                                                                                                    </span></b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.season') }}</b-th><b-td>: <span v-if="JSON.parse(proposal.season).length">
                                                                                                                        <span v-for="(item, index5) in JSON.parse(proposal.season)" :key="index5">
                                                                                                                            {{getSeason(item)}},
                                                                                                                        </span>
                                                                                                                    </span></b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('globalTrans.start_date') }}</b-th><b-td>: {{ getCurrentMonth(proposal.month_from) }}, {{ proposal.year_from }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('globalTrans.end_date') }}</b-th><b-td>: {{ getCurrentMonth(proposal.month_to) }}, {{ proposal.year_to }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.expected_output_benifit') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.expected_output : proposal.expected_output_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.estimated_cost') }}</b-th><b-td>: {{ proposal.estimated_cost }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.source_fund') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.source_fund : proposal.source_fund_bn }}</b-td></b-tr>
                                                <b-tr><b-th style="width:5%">{{ $t('external_research.priority') }}</b-th><b-td>: {{ currentLocale === 'en' ? proposal.priority : proposal.priority_bn }}</b-td></b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                    <b-col md=12 v-if="proposal.nrm_proposal_sub_main_note.length">
                                        <b-col class="text-center"><h3>Remarks</h3></b-col>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                                    <b-th>{{ $t('research_manage.review_remark') }}</b-th>
                                                    <b-th>{{ $t('globalTrans.sender') }}</b-th>
                                                    <b-th>{{ $t('globalTrans.receiver') }}</b-th>
                                                    <b-th>{{ $t('globalTrans.time') }}</b-th>
                                                    <b-th>{{ $t('globalTrans.date') }}</b-th>
                                                </b-tr>
                                            </thead>
                                            <tbody>
                                                <b-tr v-for="(data, index) in proposal.nrm_proposal_sub_main_note" :key="index">
                                                    <b-th>{{ index + 1 }}</b-th>
                                                    <b-td>{{ (currentLocale === 'en') ? data.note : data.note_bn }}</b-td>
                                                    <b-td>{{ getWho(data.send_by) }}</b-td>
                                                    <b-td>{{ getWho(data.proposal_position_status) }}</b-td>
                                                    <b-td v-if="data">{{ formatTime(data.created_at) }}</b-td>
                                                    <b-td v-if="data">{{ formatDate(data.created_at) }}</b-td>
                                                </b-tr>
                                            </tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ExportPdf from './details_pdf'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['id'],
    data () {
        return {
            loadingData: false,
            proposal: {},
            unitLoad: false,
            officerList: [],
            baseUrl: agriResearchServiceBaseUrl
        }
    },
    created () {
        if (this.id) {
            // this.loadData()
            const tmp = this.loadData()
            this.proposal = tmp
            this.getOfficerList()
        }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
        seasonList () {
            const seasonList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Kharif-1' : 'খরিফ-১', text_en: 'Kharif-1', text_bn: 'খরিফ-১' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Kharif-2' : 'খরিফ-২', text_en: 'Kharif-2', text_bn: 'খরিফ-২' }
            ]
            return seasonList
        }
    },
    methods: {
        getCurrentMonth (monthIndex) {
          const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ]
          return monthNames[monthIndex - 1]
        },
        getSeason (id) {
            const objectData = this.seasonList.find(type => type.value === parseInt(id))
            if (objectData) {
                if (this.$i18n.locale === 'bn') {
                    return objectData.text_bn
                } else {
                    return objectData.text_en
                }
            } else {
                return ''
            }
        },
        async getOfficerList () {
            if (this.proposal.org_id > 0) {
                this.unitLoad = true
                const params = { org_id: parseInt(this.proposal.org_id) }
                const response = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
                if (response.success) {
                    this.unitLoad = false
                    this.officerList = []
                    this.officerList = response.data
                } else {
                    this.unitLoad = false
                }
            }
        },
        getObjectiveName (Id) {
            if (this.proposal.description.details.length > 0) {
                const Obj = this.proposal.description.details.find(item => item.id === parseInt(Id))
                if (Obj) {
                    if (this.$i18n.locale === 'en') {
                        return Obj.specific_objective
                    } else {
                        return Obj.specific_objective_bn
                    }
                } else {
                        return ''
                }
            }
        },
        getOrgTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.organizationType.find(item => item.value === Id)
             if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getBudgetTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.find(item => item.value === parseInt(Id))
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getBudgetName (myItem) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === parseInt(myItem.budget_head_id))
            myItem.code = Obj.budget_head_code
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
         getOrgName (Id) {
            // const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === Id)
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.orgList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOfficeType (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOfficeName (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getOfficer (id) {
            const objectData = this.officerList.find(type => type.user_id === parseInt(id))
            if (objectData) {
                if (this.$i18n.locale === 'bn') {
                    return objectData.name_bn
                } else {
                    return objectData.name
                }
            } else {
                return ''
            }
        },
        getDivisionName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.divisionalResearchList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        getExpenseYearName (Id) {
            const Obj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === Id)
            if (Obj) {
                if (this.$i18n.locale === 'en') {
                    return Obj.text_en
                } else {
                    return Obj.text_bn
                }
            } else {
                    return ''
            }
        },
        loadData () {
          const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
          const data = JSON.parse(JSON.stringify(tmpData))
          return Object.assign(data)
        },
        getRelationalData (item) {
            const tmpResearchType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.researchTypeList.find(newitem => newitem.value === item.research_type)
            const tmpThematicArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === item.invitation.thematic_area_id)
            const newData = {
                researchTypeName: tmpResearchType !== undefined ? tmpResearchType.text_en : '',
                researchTypeName_bn: tmpResearchType !== undefined ? tmpResearchType.text_bn : '',
                thematic_area_name: tmpThematicArea !== undefined ? tmpThematicArea.text_en : '',
                thematic_area_name_bn: tmpThematicArea !== undefined ? tmpThematicArea.text_bn : ''
            }
            return Object.assign({}, item, newData)
        },
        getThematicArea (id) {
            const objectData = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(type => type.value === parseInt(id))
            if (objectData) {
                if (this.$i18n.locale === 'bn') {
                    return objectData.text_bn
                } else {
                    return objectData.text_en
                }
            } else {
                return ''
            }
        },
        getComponentOrgName (type, Id) {
            if (type === 1) {
                const newObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                // const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === newObj.org_id)
                const Obj = this.$store.state.orgList.find(item => item.value === newObj.org_id)
                if (Obj) {
                    if (this.$i18n.locale === 'en') {
                        return Obj.text_en
                    } else {
                        return Obj.text_bn
                    }
                } else {
                        return ''
                }
            } else {
                const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.componentOrgList.find(item => item.value === Id)
                if (Obj) {
                    if (this.$i18n.locale === 'en') {
                        return Obj.text_en
                    } else {
                        return Obj.text_bn
                    }
                } else {
                        return ''
                }
            }
        },
        formatDate (dateString) {
              const date = new Date(dateString)
              const year = date.getFullYear()
              const month = ('0' + (date.getMonth() + 1)).slice(-2)
              const day = ('0' + date.getDate()).slice(-2)
              return `${year}-${month}-${day}`
        },
         formatTime (dateString) {
              const date = new Date(dateString)
              let hours = date.getHours()
              const minutes = ('0' + date.getMinutes()).slice(-2)
              const seconds = ('0' + date.getSeconds()).slice(-2)
              const amPM = hours >= 12 ? 'PM' : 'AM'
              hours = hours % 12 || 12
              return `${hours}:${minutes}:${seconds} ${amPM}`
        },
       getWho (id) {
            const whoMap = {
                1: 'Researcher',
                2: 'P.I.',
                3: 'O.I.',
                4: 'R.C',
                5: 'R.D.'
            }
            return whoMap[id] || ''
        },
        pdfExport () {
            const reportTitle = this.$t('external_sidebar.publication_proposal_submission') + ' ' + this.$t('globalTrans.details')
            ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, this.proposal, this)
        }
    }
}
</script>
<style lang="scss">
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .contentTitle {
      font-size: 15px; background-color: #005B5B; padding: 1px
  }
</style>
